import React from "react"
import { Hero, Main, WebDesign, AppDesign } from "components"
import { content } from "content/development-services"
import { graphql, useStaticQuery } from "gatsby"
import WebCrawlers from "components/pages/services/development/WebCrawlers"

const DevelopmentServices = () => {
	const homeHero = useStaticQuery(graphql`
		query developmentHero {
			file(relativePath: { eq: "hero_development_services.png" }) {
				relativePath
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)
	return (
		<Main>
			<Hero
				img={homeHero}
				title={content.hero.title}
				text={content.hero.text}
				buttonText={content.hero.button.text}
				buttonLink={content.hero.button.path}
			/>
			<WebDesign title={content.WebApp.title} text={content.WebApp.text} image1={content.WebApp.image} />
			<AppDesign title={content.MobileApp.title} text={content.MobileApp.text} picture={content.MobileApp.image} />
			<WebCrawlers />
			<WebDesign title={content.MachLear.title} text={content.MachLear.text} image1={content.MachLear.image} />
		</Main>
	)
}

export default DevelopmentServices
