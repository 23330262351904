import React from "react"
import { Container, Section, SectionHeading } from "components"
import { Col, Image, Row } from "react-bootstrap"
import { content } from "content/development-services"
import * as styles from "./styles.module.scss"

const WebCrawlers = () => {
	return (
		<Section className={styles.banner}>
			<Container>
				<Row>
					<Col lg={6}>
						<div className="text-lg-start text-center">
							<div data-aos="zoom-in" data-aos-duration="2000">
								<Image fluid src={content.WebCrawlers.image} />
							</div>
						</div>
					</Col>
					<Col lg={{ span: 5, offset: 1 }}>
						<SectionHeading className={"text-lg-start text-center mt-5 pt-5"}>
							{content.WebCrawlers.title}
						</SectionHeading>
						<p className="text-lg-start text-center">{content.WebCrawlers.text}</p>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default WebCrawlers
