import React from "react"
import webApp1 from "../assets/images/aust_development_services_1.png"
import CrawlersImg from "../assets/images/aust_development_services_2.png"
import MachlearnImg from "../assets/images/aust_development_services_3.png"
import mobileApp from "../assets/images/aust_development_services_4.png"

export const content = {
	// Hero
	hero: {
		title: "Development services",
		text: "Want to drive your business growth? Our affordable development services are user-centric, providing you with the best cross platform apps in the market through robust reliability and quality checks. ",
		button: {
			text: "Contact us",
			path: "/contact",
		},
	},

	// WebApp development
	WebApp: {
		title: "Web App Development ",
		image: webApp1,
		text: (
			<>
				Users get their first impression of a business when they visit its website. At Venrup we specialize in making
				bespoke, user-centric websites that help your business grow exponentially. Using the best web development stack,
				our developers build web applications that are fast, responsive and optimized for use on any platform. <br></br>
				Our developers can help you build accessible and scalable web pages that are user centric with SEO optimization.
			</>
		),
	},

	// Mobile App development
	MobileApp: {
		title: "Mobile App Development",
		text: (
			<>
				Looking for a mobile app development company that can help you develop an app and improve your business
				operations? <br></br>
				<br></br>
				Venrup provides a friendly and collaborative way of working with clients on their ideas. We also help existing
				businesses generate more revenue through mobile apps. We design, develop and deliver mobile apps across iOS and
				Android platforms. Flutter, Dart and Java are just some of the languages we use to build engaging and reliable
				apps.
			</>
		),
		image: mobileApp,
	},

	// Website crawlers
	WebCrawlers: {
		title: "Website crawlers",
		text: (
			<>
				Choose our robust and effective web crawling service to extract out relevant or useful information from any
				location of the web. Gain a competitive advantage with our best web crawling tools and expertise to drive your
				business growth.
			</>
		),
		image: CrawlersImg,
	},

	// Machine Learning
	MachLear: {
		title: "Machine Learning ",
		image: MachlearnImg,
		text: (
			<>
				Businesses are using machine learning to analyze a vast amount of data to identify trends and patterns over time
				and make decisions to meet specific objectives. <br></br>
				<br></br>
				We tailor and choose the best machine learning solutions according to your business needs. Our machine learning
				services help companies to create innovative business models, solve business challenges and enable data-driven
				decision making.
			</>
		),
	},
}
